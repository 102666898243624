import { useMutation, useQuery } from '@apollo/client';
import { IconButton, Pivot, PivotItem, Stack } from '@fluentui/react';
import { InvoiceTransactionTypes_secureRowLevels } from 'ap/signing/__generated__/InvoiceTransactionTypes';
import { CarbonAccounting } from 'ap/signing/components/CarbonAccounting';
import { HistoryActionMenuView } from 'common/components/History';
import { ProtectSection } from 'common/components/Protect';
import {
  ListInvoiceNotes,
  ListInvoiceNotesVariables,
} from 'common/graphql/__generated__/ListInvoiceNotes';
import {
  UserEntityMessageCounts,
  UserEntityMessageCountsVariables,
} from 'common/graphql/__generated__/UserEntityMessageCounts';
import { loader } from 'graphql.macro';
import React, { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { InvoiceDetails_invoice } from '../../__generated__/InvoiceDetails';
import { TransactionSigningValues } from '../../types';
import { PivotCustomRender } from './PivotComponent';
import { PivotDisplay } from './PivotDisplay';
import { AttachSection } from './actionMenuContents/Attach';
import { InvoiceUploadDocument_invoiceUploadDocument } from './actionMenuContents/Attach/UploadForm/__generated__/InvoiceUploadDocument';
import { Messages } from './actionMenuContents/Messages';
import { TransactionNotesSection } from './actionMenuContents/Notes';
import { TagsSection } from './actionMenuContents/Tags';
import {
  InvoiceSigningRowProtection,
  InvoiceSigningRowProtectionVariables,
} from './actionMenuContents/__generated__/InvoiceSigningRowProtection';
import { useStyles } from './index.styles';

const INVOICE_ROW_PROTECTION = loader(
  './actionMenuContents/InvoiceSigningRowProtection.graphql'
);
const INVOICE_DETAILS = loader('../../InvoiceDetails.graphql');
const ENTITY_NOTES_LIST = loader(
  '../../../../../../common/graphql/ListInvoiceNotes.graphql'
);
const ENTITY_MESSAGE_COUNTS = loader(
  '../../.././../../../common/graphql/UserEntityMessageCounts.graphql'
);

const ActionConsts = {
  protect: 'Protect_Action',
  attach: 'Attach_Action',
  notes: 'Notes_Action',
  history: 'History_Action',
  tags: 'Tags_Action',
  messages: 'Messages_Action',
};
interface ActionMenuProps {
  invoiceDetails: InvoiceDetails_invoice;
  secureRowLevels: InvoiceTransactionTypes_secureRowLevels | undefined | null;
  carbonDeletedUpdated: (isDeletedUpdated: boolean) => void;
  onUpload?: (
    fileSelected: File,
    documentData: InvoiceUploadDocument_invoiceUploadDocument,
    toastId: string
  ) => void;
}
export const ActionsMenu: React.FC<ActionMenuProps> = ({
  invoiceDetails,
  secureRowLevels,
  carbonDeletedUpdated,
  onUpload,
}) => {
  const {
    id,
    _isUpdatable,
    secureRowLevel,
    invoiceHistoriesByEntityId,
    _isProtected,
    entityDocumentsByEntityId,
    _isEnvironmentalsExist,
    entityTagsByEntityId,
    entityEnvironmentalsByEntityId,
  } = { ...invoiceDetails };
  const {
    formState: { isDirty },
  } = useFormContext<TransactionSigningValues>();
  const [selectedKey, setSelectedKey] = React.useState(ActionConsts.protect);
  const [expandMenu, setExpandMenu] = useState(false);
  const styles = useStyles();
  const { addToast } = useToasts();
  const { data: entityNotesData, refetch: entityNotesListRefetch } = useQuery<
    ListInvoiceNotes,
    ListInvoiceNotesVariables
  >(ENTITY_NOTES_LIST, {
    variables: {
      id,
    },
  });

  const { data: userMessageCounts, refetch: userMessageCountsRefetch } =
    useQuery<UserEntityMessageCounts, UserEntityMessageCountsVariables>(
      ENTITY_MESSAGE_COUNTS,
      {
        variables: {
          entityId: id,
        },
        skip:!id,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
      }
    );

  const getEntityNotes = () => {
    const variables: ListInvoiceNotesVariables = {
      id,
    };
    entityNotesListRefetch(variables);
  };
  const getEntityNotesMemo = useCallback(getEntityNotes, [id]);

  const [invoiceRowProtection, { loading: updateLoading }] = useMutation<
    InvoiceSigningRowProtection,
    InvoiceSigningRowProtectionVariables
  >(INVOICE_ROW_PROTECTION, { errorPolicy: 'all' });

  const updateRowLevel = async (selectedLevel: string | null) => {
    const inputData =
      selectedLevel !== null
        ? {
            entityId: id,
            rowSecurityId: selectedLevel,
          }
        : {
            entityId: id,
            isProtectionRemoval: true,
          };
    const { errors } = await invoiceRowProtection({
      variables: {
        input: inputData,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: INVOICE_DETAILS,
          variables: {
            id,
          },
        },
      ],
    });

    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else
      addToast('Security/Protection level upgraded', {
        appearance: 'success',
      });
  };

  const isUpdatable = isDirty ? false : !!_isUpdatable ? true : false;

  const getSelectedSection = (key: string) => {
    switch (key) {
      case 'Protect_Action':
        return (
          <ProtectSection
            updateLoading={updateLoading}
            updateRowLevel={async (selectedLevel) => {
              updateRowLevel(selectedLevel);
            }}
            secureRowLevels={secureRowLevels?.nodes!}
            secureRowLevelId={secureRowLevel?.id}
            isUpdatable={isUpdatable}
          />
        );
      case 'Notes_Action':
        return (
          <Stack style={{ margin: '0px -10px 0px -10px' }}>
            <TransactionNotesSection
              invoiceId={id}
              data={entityNotesData?.invoice?.notesByEntityId.nodes!}
              listRefreshRequest={getEntityNotesMemo}
            />
          </Stack>
        );
      case 'History_Action':
        return (
          <HistoryActionMenuView
            moduleName="Transaction Signing"
            numberOfItems={4}
            historyData={invoiceHistoriesByEntityId.nodes || []}
          />
        );
      case 'Tags_Action':
        return <TagsSection invoiceID={id} />;
      case 'Attach_Action':
        return (
          <AttachSection
            secureRowLevel={secureRowLevels?.nodes!}
            invoice={invoiceDetails}
            onUpload={onUpload}
          />
        );
      case 'Messages_Action':
        return (
          <Messages
            onCreateMessage={userMessageCountsRefetch}
            userMessageCounts={userMessageCounts}
            invoiceID={id}
          />
        );
      default:
        return null;
    }
  };

  const _onPivotClick = (pivotName: string) => {
    setSelectedKey(pivotName);
    setExpandMenu(true);
  };

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      {expandMenu ? (
        <Stack>
          <Stack horizontalAlign="space-between" horizontal>
            <Stack horizontal className={styles.centerAlign}>
              <Pivot
                selectedKey={selectedKey}
                onLinkClick={(item: PivotItem | undefined) => {
                  setSelectedKey(item?.props.itemKey || ActionConsts.protect);
                }}
                aria-label="Menu_Actions"
              >
                <PivotItem
                  itemKey={ActionConsts.protect}
                  itemIcon={_isProtected ? 'Lock' : 'Unlock'}
                  headerText="Protect"
                  itemCount={0}
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={ActionConsts.attach}
                  itemIcon="Attach"
                  headerText="Files"
                  itemCount={entityDocumentsByEntityId.nodes?.length}
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={ActionConsts.notes}
                  itemIcon="Page"
                  headerText="Notes"
                  itemCount={
                    entityNotesData?.invoice?.notesByEntityId.nodes?.length
                  }
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={ActionConsts.history}
                  itemIcon="History"
                  headerText="History"
                  itemCount={invoiceHistoriesByEntityId.nodes?.length}
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={ActionConsts.tags}
                  itemIcon="Tag"
                  headerText="Tags"
                  itemCount={entityTagsByEntityId.nodes?.length}
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={ActionConsts.messages}
                  itemIcon="Message"
                  headerText="Messages"
                  itemCount={
                    userMessageCounts?.userEntityMessageCounts?.totalMessages!
                  }
                  onRenderItemLink={PivotCustomRender}
                />
              </Pivot>
              <IconButton
                onClick={() => setExpandMenu(false)}
                iconProps={{ iconName: 'ChevronUp' }}
                className={styles.iconButtonColor}
                ariaLabel="Collapse"
              />
            </Stack>
            <CarbonAccounting
              isEnvironmentalsExist={_isEnvironmentalsExist || false}
              environmentDeletedUpdated={(status) => {
                if (status) carbonDeletedUpdated(status);
              }}
              invoiceDetails={entityEnvironmentalsByEntityId}
              entityId={id}
              isDirty={isDirty}
            />
          </Stack>
          <Stack>{getSelectedSection(selectedKey)}</Stack>
        </Stack>
      ) : (
        <Stack horizontalAlign="space-between" horizontal>
          <Stack horizontal verticalAlign="center">
            <Stack horizontal verticalAlign="center">
              <PivotDisplay
                name="Protect"
                iconName={_isProtected ? 'Lock' : 'Unlock'}
                onPivotClick={() => _onPivotClick(ActionConsts.protect)}
              />
              <PivotDisplay
                name="Files"
                iconName="Attach"
                number={entityDocumentsByEntityId.nodes?.length}
                onPivotClick={() => _onPivotClick(ActionConsts.attach)}
              />
              <PivotDisplay
                name="Notes"
                iconName="Page"
                number={
                  entityNotesData?.invoice?.notesByEntityId?.nodes?.length
                }
                // onPivotClick={() => {
                //   setSelectedKey(ActionConsts.notes);
                //   setExpandMenu(true);
                // }}
                onPivotClick={() => _onPivotClick(ActionConsts.notes)}
              />
              <PivotDisplay
                name="History"
                iconName="History"
                number={invoiceHistoriesByEntityId.nodes?.length}
                // onPivotClick={() => {
                //   setSelectedKey(ActionConsts.history);
                //   setExpandMenu(true);
                // }}
                onPivotClick={() => _onPivotClick(ActionConsts.history)}
              />
              <PivotDisplay
                name="Tags"
                iconName="Tag"
                number={entityTagsByEntityId.nodes?.length}
                // onPivotClick={() => {
                //   setSelectedKey(ActionConsts.tags);
                //   setExpandMenu(true);
                // }}
                onPivotClick={() => _onPivotClick(ActionConsts.tags)}
              />
              <PivotDisplay
                name="Messages"
                iconName="Message"
                number={
                  userMessageCounts?.userEntityMessageCounts?.totalMessages!
                }
                // onPivotClick={() => {
                //   setSelectedKey(ActionConsts.messages);
                //   setExpandMenu(true);
                // }}
                onPivotClick={() => _onPivotClick(ActionConsts.messages)}
              />
            </Stack>
            <IconButton
              onClick={() => setExpandMenu(true)}
              iconProps={{ iconName: 'ChevronDown' }}
              className={styles.iconButtonColor}
              ariaLabel="Expand"
            />
          </Stack>
          <CarbonAccounting
            isEnvironmentalsExist={_isEnvironmentalsExist || false}
            environmentDeletedUpdated={(status) => {
              if (status) carbonDeletedUpdated(status);
            }}
            invoiceDetails={entityEnvironmentalsByEntityId}
            entityId={id}
            isDirty={isDirty}
          />
        </Stack>
      )}
    </Stack>
  );
};
