import { useMutation, useQuery } from '@apollo/client';
import { IconButton, Pivot, PivotItem, Stack } from '@fluentui/react';
import { HistoryActionMenuView } from 'common/components/History';
import { ProtectSection } from 'common/components/Protect';
import { loader } from 'graphql.macro';
import React, { useCallback, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { AccountingEntryInvoice_invoice } from '../__generated__/AccountingEntryInvoice';
import { useStyles } from './index.styles';

import { InvoiceTransactionTypes_secureRowLevels } from 'ap/signing/__generated__/InvoiceTransactionTypes';
import { CarbonAccounting } from 'ap/signing/components/CarbonAccounting';
import { InvoiceUploadDocument_invoiceUploadDocument } from 'ap/signing/transactionSigning/view/FormView/ActionMenu/actionMenuContents/Attach/UploadForm/__generated__/InvoiceUploadDocument';
import { Messages } from 'ap/signing/transactionSigning/view/FormView/ActionMenu/actionMenuContents/Messages';
import {
  InvoiceSigningRowProtection,
  InvoiceSigningRowProtectionVariables,
} from 'ap/signing/transactionSigning/view/FormView/ActionMenu/actionMenuContents/__generated__/InvoiceSigningRowProtection';
import { PivotCustomRender } from 'common/components/PivotComponent';
import { PivotDisplay } from 'common/components/PivotDisplay';
import {
  ListInvoiceNotes,
  ListInvoiceNotesVariables,
} from 'common/graphql/__generated__/ListInvoiceNotes';
import {
  UserEntityMessageCounts,
  UserEntityMessageCountsVariables,
} from 'common/graphql/__generated__/UserEntityMessageCounts';
import { AttachSection } from './ActionMenuContents/AccountingEntryAttach';
import { AccountingEntryNotesSection } from './ActionMenuContents/AccountingEntryNotes';
import { TagsSection } from './ActionMenuContents/AccountingEntryTags';

const ENTITY_NOTES_LIST = loader(
  '../../../../../common/graphql/ListInvoiceNotes.graphql'
);

const INVOICE_DETAILS = loader('../AccountingEntryInvoice.graphql');
const INVOICE_ROW_PROTECTION = loader(
  '../../../transactionSigning/view/FormView/ActionMenu/actionMenuContents/InvoiceSigningRowProtection.graphql'
);
const ENTITY_MESSAGE_COUNTS = loader(
  '../../../../../common/graphql/UserEntityMessageCounts.graphql'
);

const ActionConsts = {
  protect: 'Protect_Action',
  attach: 'Attach_Action',
  notes: 'Notes_Action',
  history: 'History_Action',
  tags: 'Tags_Action',
  messages: 'Messages_Action',
};
interface ActionMenuProps {
  // invoiceDetails: AccountingEntryInvoice | undefined;
  invoice: AccountingEntryInvoice_invoice;
  secureRowLevels: InvoiceTransactionTypes_secureRowLevels | undefined | null;
  reloaddata: () => void;
  onUpload?: (
    fileSelected: File,
    documentData: InvoiceUploadDocument_invoiceUploadDocument,
    toastId: string
  ) => void;
}
export const ActionsMenu: React.FC<ActionMenuProps> = ({
  // invoiceDetails,
  invoice,
  secureRowLevels,
  reloaddata,
  onUpload,
}) => {
  const [selectedKey, setSelectedKey] = React.useState(ActionConsts.protect);

  const [expandMenu, setExpandMenu] = useState(false);
  const styles = useStyles();
  const { addToast } = useToasts();
  const { data: entityNotesData, refetch: entityNotesListRefetch } = useQuery<
    ListInvoiceNotes,
    ListInvoiceNotesVariables
  >(ENTITY_NOTES_LIST, {
    variables: {
      // id: invoiceDetails?.invoice?.id!,
      id: invoice.id,
    },
  });

  const { data: userMessageCounts, refetch: userMessageCountsRefetch } =
    useQuery<UserEntityMessageCounts, UserEntityMessageCountsVariables>(
      ENTITY_MESSAGE_COUNTS,
      {
        variables: {
          // entityId: invoiceDetails?.invoice?.id!,
          entityId: invoice.id,
        },
        skip: !invoice.id,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
      }
    );

  const getEntityNotes = () => {
    const variables: ListInvoiceNotesVariables = {
      // id: invoiceDetails?.invoice?.id!,
      id: invoice.id,
    };
    entityNotesListRefetch(variables);
  };
  const getEntityNotesMemo = useCallback(getEntityNotes, [
    // invoiceDetails?.invoice?.id!,
    invoice.id,
  ]);
  const [updateRowProtection, { loading: updateLoading, error: updateError }] =
    useMutation<
      InvoiceSigningRowProtection,
      InvoiceSigningRowProtectionVariables
    >(INVOICE_ROW_PROTECTION);
  const updateRowLevel = async (selectedLevel: string | null) => {
    const inputData =
      selectedLevel !== null
        ? {
            // entityId: invoiceDetails?.invoice?.id!,
            entityId: invoice.id,
            rowSecurityId: selectedLevel,
          }
        : {
            // entityId: invoiceDetails?.invoice?.id!,
            entityId: invoice.id,
            isProtectionRemoval: true,
          };
    await updateRowProtection({
      variables: {
        input: inputData,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: INVOICE_DETAILS,
          variables: {
            // id: invoiceDetails?.invoice?.id,
            id: invoice.id,
          },
        },
      ],
    });
    if (!updateError) {
      addToast('Security/Protection level upgraded', {
        appearance: 'success',
      });
    } else
      addToast('Failed to upgrade protection level', {
        appearance: 'error',
      });
  };
  const getSelectedSection = (key: string) => {
    switch (key) {
      case 'Protect_Action':
        return (
          <ProtectSection
            secureRowLevels={secureRowLevels?.nodes!}
            // secureRowLevelId={invoiceDetails?.invoice?.secureRowLevel?.id}
            secureRowLevelId={invoice.secureRowLevel?.id}
            updateLoading={updateLoading}
            updateRowLevel={(selectedLevel) => {
              updateRowLevel(selectedLevel);
            }}
          />
        );
      case 'Notes_Action':
        return (
          <AccountingEntryNotesSection
            // invoiceId={invoiceDetails?.invoice?.id!}
            invoiceId={invoice.id}
            data={entityNotesData?.invoice?.notesByEntityId.nodes!}
            listRefreshRequest={getEntityNotesMemo}
          />
        );
      case 'History_Action':
        return (
          <HistoryActionMenuView
            moduleName="Transaction Signing"
            numberOfItems={4}
            historyData={invoice.invoiceHistoriesByEntityId.nodes || []}
          />
        );
      case 'Tags_Action':
        return (
          <TagsSection
            invoiceID={invoice.id}
            // invoiceID={invoiceDetails?.invoice?.id}
          />
        );
      case 'Attach_Action':
        return (
          <AttachSection
            secureRowLevel={secureRowLevels?.nodes!}
            // invoice={invoiceDetails}
            invoice={invoice}
            onUpload={onUpload}
          />
        );
      case 'Messages_Action':
        return (
          <Messages
            onCreateMessage={userMessageCountsRefetch}
            userMessageCounts={userMessageCounts}
            // invoiceID={invoiceDetails?.invoice?.id!}
            invoiceID={invoice.id}
          />
        );
      default:
        return null;
    }
  };
  return (
    <Stack>
      {expandMenu ? (
        <Stack>
          <Stack horizontalAlign="space-between" horizontal>
            <Stack horizontal className={styles.centerAlign}>
              <Pivot
                selectedKey={selectedKey}
                onLinkClick={(item: PivotItem | undefined) => {
                  setSelectedKey(item?.props.itemKey || ActionConsts.protect);
                }}
                aria-label="Menu_Actions"
              >
                <PivotItem
                  itemKey={ActionConsts.protect}
                  itemIcon={invoice._isProtected ? 'Lock' : 'Unlock'}
                  headerText="Protect"
                  itemCount={0}
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={ActionConsts.attach}
                  itemIcon="Attach"
                  headerText="Files"
                  itemCount={
                    invoice.entityDocumentsByEntityId.nodes.length || 0
                  }
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={ActionConsts.notes}
                  itemIcon="Page"
                  headerText="Notes"
                  itemCount={
                    entityNotesData?.invoice?.notesByEntityId.nodes.length
                  }
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={ActionConsts.history}
                  itemIcon="History"
                  headerText="History"
                  itemCount={
                    invoice.invoiceHistoriesByEntityId.nodes.length || 0
                  }
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={ActionConsts.tags}
                  itemIcon="Tag"
                  headerText="Tags"
                  itemCount={invoice.entityTagsByEntityId.nodes.length || 0}
                  onRenderItemLink={PivotCustomRender}
                />
                <PivotItem
                  itemKey={ActionConsts.messages}
                  itemIcon="Message"
                  headerText="Messages"
                  itemCount={
                    userMessageCounts?.userEntityMessageCounts?.messagesSent!
                  }
                  onRenderItemLink={PivotCustomRender}
                />
              </Pivot>
              <IconButton
                onClick={() => setExpandMenu(false)}
                iconProps={{ iconName: 'ChevronUp' }}
                className={styles.iconButtonColor}
                ariaLabel="Collapse"
              />
            </Stack>
            {invoice && (
              <CarbonAccounting
                isEnvironmentalsExist={invoice._isEnvironmentalsExist || false}
                environmentDeletedUpdated={(status) => {
                  if (status) reloaddata();
                }}
                invoiceDetails={invoice.entityEnvironmentalsByEntityId}
                entityId={invoice.id}
                isDirty={false}
              />
            )}
          </Stack>
          <Stack>{getSelectedSection(selectedKey)}</Stack>
        </Stack>
      ) : (
        <Stack
          verticalAlign="center"
          horizontal
          horizontalAlign="space-between"
        >
          <Stack horizontal verticalAlign="center">
            <Stack horizontal verticalAlign="center">
              <PivotDisplay
                name="Protect"
                iconName={invoice._isProtected ? 'Lock' : 'Unlock'}
                onPivotClick={() => {
                  setSelectedKey(ActionConsts.protect);
                  setExpandMenu(true);
                }}
              />
              <PivotDisplay
                name="Files"
                iconName="Attach"
                count={invoice.entityDocumentsByEntityId.nodes.length}
                onPivotClick={() => {
                  setSelectedKey(ActionConsts.attach);
                  setExpandMenu(true);
                }}
              />
              <PivotDisplay
                name="Notes"
                iconName="Page"
                count={invoice.notesByEntityId.nodes.length}
                onPivotClick={() => {
                  setSelectedKey(ActionConsts.notes);
                  setExpandMenu(true);
                }}
              />
              <PivotDisplay
                name="History"
                iconName="History"
                count={invoice.invoiceHistoriesByEntityId.nodes.length}
                onPivotClick={() => {
                  setSelectedKey(ActionConsts.history);
                  setExpandMenu(true);
                }}
              />
              <PivotDisplay
                name="Tags"
                iconName="Tag"
                count={invoice.entityTagsByEntityId.nodes.length}
                onPivotClick={() => {
                  setSelectedKey(ActionConsts.tags);
                  setExpandMenu(true);
                }}
              />
              <PivotDisplay
                name="Messages"
                iconName="Message"
                count={
                  userMessageCounts?.userEntityMessageCounts?.messagesSent!
                }
                onPivotClick={() => {
                  setSelectedKey(ActionConsts.messages);
                  setExpandMenu(true);
                }}
              />
            </Stack>
            <IconButton
              onClick={() => setExpandMenu(true)}
              iconProps={{ iconName: 'ChevronDown' }}
              className={styles.iconButtonColor}
              ariaLabel="Expand"
            />
          </Stack>
          {invoice && (
            <CarbonAccounting
              isEnvironmentalsExist={invoice._isEnvironmentalsExist || false}
              environmentDeletedUpdated={(status) => {
                if (status) reloaddata();
              }}
              invoiceDetails={invoice.entityEnvironmentalsByEntityId}
              entityId={invoice.id}
              isDirty={false}
            />
          )}
        </Stack>
      )}
    </Stack>
  );
};
